import { useEffect, useRef, useState } from "react";
import { AdminApi, api, resolveUrl } from "../config/apis";
import { useAuth } from "../hooks/auth/auth";
const { REACT_APP_SERVER_BASE } = process.env;

export const GetAuthState = (key: string) => {
  const { state } = useAuth();

  switch (key) {
    case "USER":
      return state != null && state !== undefined ? state.user : false;
    case "ACCESS_TOKEN":
      return state != null && state !== undefined ? state.access_token : false;
    case "ROLE":
      return state != null && state !== undefined ? state.userRole : false;
    case "ID":
      return state != null && state !== undefined ? state.user.id : false;
    case "PERMISSION":
      return state != null && state !== undefined
        ? state.userRolePermissions
        : false;
    default:
      return state;
  }
};

export const GetUserName = () => {
  const user = GetAuthState("USER");
  if (user) return `${user.first_name} ${user.last_name}`;
};
export const GetUserProfileImage = () => {
  const user = GetAuthState("USER");
  if (user && user.userMeta) {
    return `${user.userMeta.profile_picture}`;
  }
};

export const GetUserRole = () => {
  return GetAuthState("ROLE");
};

export const isAdminUser = () => {
  const userRole = GetUserRole();
  return userRole && userRole.includes("Admin");
};

export const GetUserId = () => {
  return GetAuthState("ID");
};

export const getTeachers = async () => {
  const teacher = await api.get(resolveUrl(AdminApi.TEACHERS));
  return teacher.data.teachers;
};
export const getAssinTeachers = async (id: string | number) => {
  const teacher = await api.get(resolveUrl(AdminApi.ASSINED_TEACHERS, `${id}`));
  return teacher.data.teachers;
};

export const getCourseView = async (id: string | number) => {
  const courseview = await api.get(resolveUrl(AdminApi.COURSES_VIEW, `${id}`));
  return courseview.data;
};

export const getCourseDemoVideo = async (id: string | number) => {
  const res = await api.get(resolveUrl(AdminApi.Demo_Video, `${id}`));
  return res.data;
};

export const getAllCourses = async () => {
  const res = await api.get(resolveUrl(AdminApi.COURSES_PAGINATE));
  return res.data;
};

export const getAllCoursesOutlet = async (text?: string, limit?: number) => {
  const params: { [key: string]: any } = { limit: limit || 2 };
  if (text) {
    params.text = text;
  }
  const res = await api.get(resolveUrl(AdminApi.COURSES_PAGINATE), { params });
  return res.data;
};

export const getAllStuCoursesOutlet = async (
  id: number | string,
  text?: string,
  limit?: number
) => {
  const params: { [key: string]: any } = { limit: limit || 2 };
  if (text) {
    params.text = text;
  }
  const res = await api.get(
    resolveUrl(`${AdminApi.STUDENT_ASSIGN_COURSES}, ${id}`),
    { params }
  );
  return res.data;
};

export const getForumView = async (
  id: string | number,
  fromDate: string = "",
  toDate: string = "",
  text?: string,
  sort?: string
) => {
  const queryParams = [];
  if (fromDate) {
    queryParams.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    queryParams.push(`toDate=${toDate}`);
  }
  if (text) {
    queryParams.push(`text=${encodeURIComponent(text)}`);
  }
  if (sort) {
    queryParams.push(`sort=${sort}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
  const forumview = await api.get(
    resolveUrl(AdminApi.FORUM_VIEW, `${id}${queryString}`)
  );

  return forumview.data;
};

export const getThreads = async (slug: string | number, page: number, text:string) => {
  const forumview = await api.get(
    resolveUrl(AdminApi.FORUM_THREAD_VIEW, `${slug}`),
    {
      params: { 
        limit:page,
        keyword:text,
      }
      
    }
    
  );
  return forumview.data;
};
export const getComments = async (id: string | number) => {
  const res = await api.get(resolveUrl(AdminApi.FORUM_COMMENTS_VIEW, `${id}`));
  return res.data;
};
export const getStudentData = async (id: string | number) => {
  const res = await api.get(resolveUrl(AdminApi.CHILD_LIST, `${id}`));
  return res.data;
};
export const getSingleStuData = async (id: string | number) => {
  const res = await api.get(resolveUrl(AdminApi.STUDENT_SINGLE_CARD, `${id}`));
  return res.data;
};

export const getSingleStuStat = async (
  studentId: string | number,
  courseId?: string | number
) => {
  const params: {
    studentId: string | number;
    courseId: string | number | undefined;
  } = {
    studentId,
    courseId,
  };
  if (courseId) params.courseId = courseId;
  const res = await api.get(AdminApi.STUDENT_STATS, { params });
  return res.data;
};
export const getSingleStuDetail = async (
  studentId: string | number,
  courseId?: string | number
) => {
  const params: {
    studentId: string | number;
    courseId: string | number | undefined;
  } = {
    studentId,
    courseId,
  };
  if (courseId) params.courseId = courseId;
  const res = await api.get(AdminApi.STUDENT_STATS, { params });
  return res.data;
};

export const getCartData = async (id: string | number) => {
  const res = await api.get(resolveUrl(AdminApi.ADD_TO_CART_LIST, `${id}`));
  return res.data;
};

export const getStudentCourseData = async (id: string | number) => {
  const response = await api.get(
    resolveUrl(AdminApi.STUDENT_ASSIGN_COURSES, `${id}`)
  );
  return response.data;
};
export const getTeachersData = async (id: string | number) => {
  const response = await api.get(
    resolveUrl(AdminApi.STUDENT_COURSES_TEACHER, `${id}`)
  );
  return response.data;
};

export const getStudentAssignments = async (
  id: string | number,
  filterState: any
) => {
  const { teacherId, status, searchQuery, order, limit } = filterState;

  const params = {
    order,
    teacherId,
    status,
    searchQuery,
    page: 1,
    limit: limit,
  };

  const res = await api.get(
    resolveUrl(AdminApi.STUDENT_ASSIGN_ASSIGNMENTS, `${id}`),
    { params }
  );
  return res.data;
};

export const getAssignmentsDetail = async (id: string | number) => {
  const res = await api.get(
    resolveUrl(AdminApi.STUDENT_ASSIGNMENT_VIEW, `${id}`)
  );
  return res.data;
};

export const getKidsData = async (id: string | number, filterState: any) => {
  const { searchQuery, order, limit } = filterState;

  const params = {
    order,
    searchQuery,
    page: 1,
    limit: limit,
  };

  const res = await api.get(resolveUrl(AdminApi.CHILD_LIST, `${id}`), {
    params,
  });
  return res.data;
};

export const getStudentQuizes = async (
  id: string | number,
  filterState: any
) => {
  const { teacherId, status, searchQuery, order, limit, courseSlug } =
    filterState;

  const params = {
    order,
    teacherId,
    status,
    searchQuery,
    page: 1,
    limit: limit,
    courseSlug,
  };

  const res = await api.get(
    resolveUrl(AdminApi.STUDENT_ASSIGN_QUIZZES, `${id}`),
    { params }
  );
  return res.data;
};

export const getObjectKeyValue = (
  Arr: any,
  findValueKey: string,
  findByKey: string,
  findByValue: any
) => {
  let value = "";

  Arr.forEach((item: any) => {
    if (item.hasOwnProperty(findByKey) && item[findByKey] === findByValue) {
      value = item[findValueKey];
    }
  });

  return value;
};

export const toTitleCase = (str: string | null) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  return `<span class="minutes">${minutes
    .toString()
    .padStart(2, "0")}</span>:<span class="seconds">${seconds
      .toString()
      .padStart(2, "0")}</span>`;
};

export const minutesToSeconds = (minutes: number): number => {
  return minutes * 60;
};

export const getResultData = async (id: string | number) => {
  const response = await api.get(
    resolveUrl(AdminApi.STUDENT_QUIT_RESULT, `${id}`)
  );
  return response.data;
};

export const getQuizesBank = async () => {
  const response = await api.get(resolveUrl(AdminApi.QUESTIONBANK_PAGINATE), {
    params: {
      all: 1,
    },
  });
  return response.data;
};

export const UserStudentDetails = async (id: string | number) => {
  const response = await api.get(
    resolveUrl(AdminApi.USER_STUDENT_DETAIL, `${id}`)
  );
  return response.data;
};
export const UserParentDetails = async (id: string | number) => {
  const response = await api.get(
    resolveUrl(AdminApi.USER_PARENT_DETAIL, `${id}`)
  );
  return response.data;
};
export const UserTeacherDetails = async (id: string | number) => {
  const response = await api.get(
    resolveUrl(AdminApi.USER_TEACHER_DETAIL, `${id}`)
  );
  return response.data;
};

export function truncateTextWithEllipsis(text: string, maxLength: any) {
  if (typeof text !== "string" || text.length <= maxLength) {
    return text;
  }

  return text.substring(0, maxLength) + "...";
}

export function GetLastPartOfFileName(filename: string, delimiter = ".") {
  const parts = filename.split(delimiter);
  return parts[parts.length - 1];
}

export const PopupStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  width: "100%",
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

export const FetchPopupData = async (type: string, setPopupData: any) => {
  try {
    const res = await api.get(
      resolveUrl(AdminApi.FRONTEND_CMS_EDITOR_VIEW, type)
    );
    setPopupData(res.data.content ?? "");
  } catch (error) {
    console.error(error);
  }
};

export const UseTimeTracker = (moduleName: any) => {
  const startTimeRef = useRef<any>(new Date());
  useEffect(() => {
    return () => {
      const endTime: any = new Date();
      const timeSpent = endTime - startTimeRef.current;
      const minutes = Math.floor(timeSpent / 60000);
      const seconds = ((timeSpent % 60000) / 1000).toFixed(0);
    };
  }, [moduleName]);

  const getStartTime = () => startTimeRef.current;

  return { getStartTime };
};

export const TrackMouse = () => {
  const trackedElement = document.body;
  function handleMouseMove(event: { clientX: any; clientY: any }) {
    const mouseX = event.clientX;
    const mouseY = event.clientY;
  }
  trackedElement.addEventListener("mousemove", handleMouseMove);
};

export const HasPermission = ({ permission, children }: any) => {
  const { state } = useAuth();

  const hasPermission =
    state &&
    state.userRolePermissions &&
    ((typeof permission === "string" &&
      state.userRolePermissions.includes(permission)) ||
      (Array.isArray(permission) &&
        permission.some((perm) => state.userRolePermissions.includes(perm))) ||
      state.userRole === "Super Admin");

  return hasPermission ? children : null;
};
export const HasPermissionTab = (permission: any) => {
  const { state } = useAuth();
  return (
    state &&
    (state.userRolePermissions.includes(permission) ||
      state.userRole === "Super Admin")
  );
};
// export const HasPermissionMenu = ({
//   notForAdmin,
//   permission,
//   children,
// }: any) => {
//   const { state } = useAuth();
//   if (permission === "NR") {
//     return children;
//   }
//   const hasPermission =
//     state &&
//     state.userRolePermissions &&
//     ((typeof permission === "string" &&
//       state.userRolePermissions.includes(permission)) ||
//       (Array.isArray(permission) &&
//         permission.some((perm) => state.userRolePermissions.includes(perm))));

//   // Check if the user is Super Admin and the menu item is marked notForAdmin
//   const isSuperAdminExcluded =
//     state && state.userRole === "Super Admin" && notForAdmin;

//   if (
//     (hasPermission || (state && state.userRole === "Super Admin")) &&
//     !isSuperAdminExcluded
//   ) {
//     return children;
//   }

//   return null;
// };

export const HasPermissionMenu = ({
  notForAdmin,
  notForParent, // New prop to exclude items for Parent users
  permission,
  children,
}: any) => {
  const { state } = useAuth();

  // If permission is "NR", render children directly
  if (permission === "NR") {
    return children;
  }

  // Determine if the user has the required permission
  const hasPermission =
    state &&
    state.userRolePermissions &&
    ((typeof permission === "string" &&
      state.userRolePermissions.includes(permission)) ||
      (Array.isArray(permission) &&
        permission.some((perm) => state.userRolePermissions.includes(perm))));

  // Check if the user is Super Admin and the menu item is marked notForAdmin
  const isSuperAdminExcluded =
    state && state.userRole === "Super Admin" && notForAdmin;

  // Check if the user is a Parent and the menu item is marked notForParent
  const isParentExcluded = state && state.userRole === "Parent" && notForParent;

  // Determine if the item should be rendered
  if (
    (hasPermission || (state && state.userRole === "Super Admin")) &&
    !isSuperAdminExcluded &&
    !isParentExcluded
  ) {
    return children;
  }

  // Return null if the item should not be rendered
  return null;
};

export const convertMinutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const seconds = remainingMinutes * 60;

  let timeString = "";

  if (hours > 0) {
    timeString += `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  if (remainingMinutes > 0) {
    if (timeString !== "") {
      timeString += ", ";
    }
    timeString += `${remainingMinutes} min${remainingMinutes > 1 ? "s" : ""}`;
  }

  // Only add seconds if there are no hours or minutes
  if (hours === 0 && remainingMinutes === 0) {
    if (timeString !== "") {
      timeString += ", ";
    }
    timeString += `${seconds} sec${seconds > 1 ? "s" : ""}`;
  }

  return timeString;
};

export const useReadMore = (maxLength: number) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text: string) => {
    if (typeof text !== "string" || text.length <= maxLength) {
      return text;
    }

    return isExpanded ? text : `${text.slice(0, maxLength)}...`;
  };

  return {
    isExpanded,
    toggleReadMore,
    truncateText,
  };
};

export const useReadMorePost = (defaultLength: number) => {
  const [expandedStates, setExpandedStates] = useState<any>({});

  const isExpanded = (index: string | number) => expandedStates[index];

  const toggleReadMore = (index: string | number) => {
    setExpandedStates((prevState: { [x: string]: any }) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return {
    isExpanded,
    toggleReadMore,
    truncateText: (text: string) =>
      text.length > defaultLength ? text.slice(0, defaultLength) + "..." : text,
  };
};

export const makeFullUrl = (relativePath: string) => {
  if (!REACT_APP_SERVER_BASE || !relativePath) {
    throw new Error("Base URL or relative path is missing");
  }
  // Ensure REACT_APP_SERVER_BASE ends with '/'
  const baseUrl = REACT_APP_SERVER_BASE.endsWith("/")
    ? REACT_APP_SERVER_BASE
    : REACT_APP_SERVER_BASE + "/";

  // Ensure relativePath doesn't start with '/'
  const path = relativePath.startsWith("/")
    ? relativePath.substring(1)
    : relativePath;
  return `${baseUrl}${path}`;
};

export function limitText(
  text: string,
  limit: number,
  addEllipsis: boolean = true
): string {
  if (typeof text !== "string") {
    throw new TypeError("First argument must be a string");
  }
  if (typeof limit !== "number" || limit < 0) {
    throw new TypeError("Second argument must be a non-negative number");
  }

  if (text.length <= limit) {
    return text;
  }

  const ellipsis = addEllipsis ? "..." : "";
  const trimmedText = text.slice(0, limit);

  return addEllipsis
    ? trimmedText.slice(0, -ellipsis.length) + ellipsis
    : trimmedText;
}

export const activityLog = async () => {
  return await api.get(AdminApi.TIME_TRACKING);
};

export const checkLogoutTime = (dispatch: any) => {
  const getloginTime = localStorage.getItem("loginTime");
  const loginTime = getloginTime && Number(getloginTime);
  if (loginTime) {
    const currentTime = new Date().getTime();

    const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;
    // const sevenDaysInMillis = 60000;

    if (currentTime - loginTime > sevenDaysInMillis) {
      dispatch({ type: "LOGOUT" });
      localStorage.removeItem("auth");
      localStorage.removeItem("loginTime");
      activityLog();
    }
  }
};
